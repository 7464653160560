/** Simple drag behaviour for horizontal reels
 *  ------------------------------------------------------------------------------------------------
**/

export const init = () => {
  const targets = document.querySelectorAll('.js--draggable');

  (targets || []).forEach(
    (_drag) => {
      const drag = _drag;
      let isDown = false;
      let startX;
      let scrollLeft;

      drag.addEventListener('mousedown', (e) => {
        // only left clicks drag
        if (!(e.which > 1 || e.shiftKey || e.altKey || e.metaKey || e.ctrlKey)) {
          isDown = true;
          drag.classList.add('dragging');
          drag.style.scrollSnapType = 'none';
          startX = e.pageX - drag.offsetLeft;
          scrollLeft = drag.scrollLeft;
        }
      });
      drag.addEventListener('mouseleave', () => {
        isDown = false;
        drag.style.removeProperty('scroll-snap-type');
        drag.classList.remove('dragging');
      });
      drag.addEventListener('mouseup', () => {
        isDown = false;
        drag.style.removeProperty('scroll-snap-type');
        drag.classList.remove('dragging');
      });
      drag.addEventListener('mousemove', (e) => {
        if (!isDown) {
          return;
        }
        e.preventDefault();
        const x = e.pageX - drag.offsetLeft;
        const walk = (x - startX) * 3; // scroll-fast
        drag.scrollLeft = scrollLeft - walk;
        // console.log(walk);
      });
    },
  );
};

export default init;
